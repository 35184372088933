import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"

import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"
import Select from "react-select"

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import { useSiteVersion } from "hooks/useSiteVersion"
import DeleteModal from "components/Common/DeleteModal"
import { deleteModel, getBrands, getModels } from "store/actions"
import FormModal from "./Crud/Update/Form"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const AllModels = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { isPremium } = useSiteVersion()
  const { ALlModels, total, loading, allBrand, brandLoading } = useSelector(
    state => ({
      ALlModels: state.Model.models?.models,
      total: state.Model.models?.total,
      allBrand: state.Brand.brands?.brands,
      loading: state.Model.loading,
      brandLoading: state.Brand.searchLoading,
    })
  )

  // console.log(allBrand);

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [pageModel, setPageModel] = useState(1)
  const [createModal, setCreateModal] = useState({ status: false, data: "" })
  const [userSearchText, setUserSearchText] = useState("")

  const [brandValue, setBrandValue] = useState("Select Brand...")
  const [brandId, setBrandId] = useState("")
  const [brandSearch, setBrandSearch] = useState("")

  //delete
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteModel(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const modelPages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getModels(pageModel, totalItemLimit, userSearchText, brandId))
  }, [dispatch, pageModel, totalItemLimit, userSearchText, brandId])

  const onCloseClickCreate = () => {
    setCreateModal({ status: false, data: "" })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]
  const updatedLocation = {
    ...location,
    state: {
      ...location.state,
      page: 1,
    },
  }

  const modelData = map(ALlModels, (item, index) => ({
    ...item,

    createdAt: moment(item.createdAt).format("DD/MM/YYYY - h:mm A"),

    action: (
      <div>
        {isPremium && (
          <Button
            id={`view${index}`}
            type="button"
            color="white"
            className="ms-1 btn"
            onClick={() => setCreateModal({ status: true, data: item })}
          >
            <i
              className="bx bx-show-alt me-2"
              style={{ color: "green", fontSize: "22px" }}
            />
          </Button>
        )}
        <Button
          id={`delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item?._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
    description: (
      <p>
        {item?.description?.length >= 80
          ? item?.description?.slice(0, 80 - 1) + "..."
          : item?.description}
      </p>
    ),
  }))

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const debounceUserSearch = debounce(value => {
    setUserSearchText(value)
    history.push(updatedLocation)
  }, 600)

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  useEffect(() => {
    dispatch(getBrands(1, "user", 10, brandSearch))
  }, [dispatch, brandSearch])

  // const debounceBrandSearch = debounce(value => setBrandSearch(value), 600)

  const handleEnter = textEntered => {
    setBrandSearch(textEntered)
  }

  function handlerFinalValuesBrand(event) {
    setBrandValue(event.label)
    setBrandId(event.value)
    history.push(updatedLocation)
  }

  const brandOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (allBrand?.length >= 1 ? allBrand : [])?.map(
        (results, index) => ({
          key: index,
          label: results.name,
          value: results._id,
        })
      ),
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <FormModal
        show={createModal?.status}
        onCloseClick={onCloseClickCreate}
        updateData={createModal?.data}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={modelData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="py-2">
                        <Col md="5">
                          {" "}
                          <div className="w-100 search-box pt-1 d-inline-block">
                            <div className="position-relative">
                              <AvForm>
                                <AvField
                                  name="searchText"
                                  placeholder="Search"
                                  type="text"
                                  onChange={e =>
                                    debounceUserSearch(e.target.value)
                                  }
                                />
                              </AvForm>
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        {/* <Col md="8"> */}{" "}
                        {/* <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                            <div className="mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="" style={{ width: "50px" }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={totalItemLimit}
                                onChange={e => handlePageLimit(e)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm> */}
                        {/* </Col> */}
                        <Col md="7">
                          <div className="w-100 px-1 mb-0 py-2">
                            {/* <label>Brand</label> */}
                            <Select
                              onInputChange={handleEnter}
                              value={brandValue}
                              placeholder={brandValue}
                              onChange={handlerFinalValuesBrand}
                              options={brandOptions}
                              classNamePrefix="select2-selection"
                              isLoading={brandLoading}
                            />{" "}
                          </div>
                        </Col>
                        <div className="d-flex justify-content-end">
                          <Button
                            className="px-3"
                            color="warning"
                            onClick={() => setCreateModal({ status: true })}
                          >
                            <i className="mdi mdi-plus me-1" />
                            New Model
                          </Button>
                        </div>
                      </Row>

                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {ALlModels?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No Datas!
                            </p>
                          )}
                          <MyPagination
                            totalPages={modelPages}
                            page={pageModel}
                            setPage={setPageModel}
                            title="mdl"
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllModels
