// import React from "react"
// import { map } from "lodash"
// import { Col, Input, Row } from "reactstrap"
// import { useHistory, useLocation } from "react-router-dom"
// import PropTypes from "prop-types"

// const MyPagination = ({ totalPages, page, setPage, title }) => {
//   const history = useHistory()
//   const location = useLocation()

//   const urlTitle = title || "p"

//   const [pageNumber, setPageNumber] = React.useState(location?.state?.page ?? 1)

//   const apiPage = () => {
//     if (page >= totalPages.length) {
//       return totalPages.length
//     }
//     if (page < 1) {
//       return 1
//     } else {
//       return page
//     }
//   }
//   const OnlyFive =
//     totalPages.length > 5 && parseInt(page) > 5
//       ? totalPages.slice(parseInt(page) - 5, parseInt(page) + 5)
//       : totalPages.slice(0, parseInt(page) + 5)

//   const [showOnlyFive, setShowOnlyFive] = React.useState(OnlyFive)

//   React.useEffect(() => {
//     if (OnlyFive?.length <= 10) {
//       setShowOnlyFive(OnlyFive)
//     }
//   }, [page, location?.state?.page])

//   const sort =
//     location?.state && location?.state?.sort ? location.state.sort : "all"

//   const handleNextClick = () => {
//     setPage(page + 1)
//     history.push({
//       pathname: location.pathname,
//       search: `${urlTitle}=${page + 1}&s=${sort}`,
//       state: {
//         [urlTitle]: page + 1,
//         sort: sort,
//       },
//     })
//   }
//   const handlePrevClick = () => {
//     setPage(page - 1)
//     history.push({
//       pathname: location.pathname,
//       search: `${urlTitle}=${page - 1}&s=${sort}`,
//       state: {
//         [urlTitle]: page - 1,
//         sort: sort,
//       },
//     })
//   }
//   const handleNumClick = item => {
//     setPage(item)
//     history.push({
//       pathname: location.pathname,
//       search: `${urlTitle}=${item}&s=${sort}`,
//       state: {
//         [urlTitle]: item,
//         sort: sort,
//       },
//     })
//   }

//   React.useEffect(() => {
//     if (location?.state && location?.state?.page) {
//       setPage(location?.state?.page)
//     }
//   }, [location?.state?.page])

//   const handleSubmit = e => {
//     e.preventDefault()
//     setPage(pageNumber)
//     history.push({
//       pathname: location.pathname,
//       search: `${urlTitle}=${pageNumber}&s=${sort}`,
//       state: {
//         page: pageNumber,
//         sort: sort,
//       },
//     })
//   }

//   return (
//     <Row
//       className="align-items-md-center  mt-30 "
//       style={{ marginTop: "2rem" }}
//     >
//       <Col
//         style={{
//           background: "#EFF5F5",
//           padding: 12,
//           borderRadius: 8,
//         }}
//         className="align-items-center inner-custom-pagination  d-flex pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination"
//       >
//         <form
//           onSubmit={handleSubmit}
//           className="d-flex align-items-center"
//           style={{ flex: 1 }}
//         >
//           <p className="p-0 m-0 pe-1 font-size-12">Page </p>
//           <Input
//             name="page"
//             type="number"
//             min={1}
//             max={totalPages?.length}
//             style={{ width: 45, height: 30, border: "1px solid green" }}
//             value={pageNumber}
//             onChange={e => setPageNumber(e.target.value)}
//             // defaultValue={pageNumber}
//           />
//           <p className="p-0 m-0 px-1 font-size-12"> of {totalPages?.length}</p>
//           <button
//             type="submit"
//             style={{ width: 60, height: 30 }}
//             className={`ms-2 btn tickbtn d-flex align-items-center justify-contetn-center  btn-warning`}
//           >
//             Go
//             <i className="fas fa-angle-right ms-1"></i>
//           </button>
//         </form>
//         <div className="text-md-right overflowScroll">
//           {page <= 1 ? (
//             <></>
//           ) : (
//             <div
//               className="btn-group me-0 "
//               role="group"
//               aria-label="First group"
//             >
//               <span
//                 style={{
//                   borderRadius: "50%",
//                   border: "none",
//                 }}
//                 className="btn btn-outline-light text-info "
//                 onClick={handlePrevClick}
//               >
//                 <i className="fas fa-angle-left"></i>
//               </span>
//             </div>
//           )}
//           {totalPages?.length >= 2 && (
//             <div
//               className="btn-group me-2 "
//               role="group"
//               aria-label="Second group"
//             >
//               {map(showOnlyFive, (item, index) => (
//                 <span
//                   key={index}
//                   className="btn btn-outline-warning"
//                   onClick={() => handleNumClick(item)}
//                   style={{
//                     borderRadius: "50%",
//                     marginLeft: "5px",
//                     marginRight: "5px",
//                     border: "none",
//                     backgroundColor: apiPage() == item && "#EC942C",
//                     color: apiPage() == item && "#fff",
//                   }}
//                 >
//                   {item}
//                 </span>
//               ))}
//             </div>
//           )}
//           {page >= totalPages?.length ? (
//             <></>
//           ) : (
//             <div className="btn-group" role="group" aria-label="Third group">
//               <span
//                 className="btn btn-outline-light text-warning"
//                 style={{
//                   borderRadius: "50%",
//                   border: "none",
//                 }}
//                 onClick={handleNextClick}
//               >
//                 <i className="fas fa-angle-right"></i>
//               </span>
//             </div>
//           )}
//         </div>
//       </Col>
//     </Row>
//   )
// }

// MyPagination.propTypes = {
//   totalPages: PropTypes.array,
//   page: PropTypes.any,
//   setPage: PropTypes.func,
//   title: PropTypes.any,
// }

// export default MyPagination

import React from "react"
import { map } from "lodash"
import { Col, Input, Row } from "reactstrap"
import { useHistory, useLocation } from "react-router-dom"
import PropTypes from "prop-types"

const MyPagination = ({ totalPages, page, setPage, title }) => {
  const history = useHistory()
  const location = useLocation()

  const [pageNumber, setPageNumber] = React.useState(location?.state?.page ?? 1)
  const urlTitle = title || "page"

  const apiPage = () => {
    if (page >= totalPages.length) {
      return totalPages.length
    }
    if (page < 1) {
      return 1
    } else {
      return page
    }
  }
  const OnlyFive =
    totalPages.length > 5 && parseInt(page) > 5
      ? totalPages.slice(parseInt(page) - 5, parseInt(page) + 5)
      : totalPages.slice(0, parseInt(page) + 5)

  const [showOnlyFive, setShowOnlyFive] = React.useState(OnlyFive)

  React.useEffect(() => {
    if (OnlyFive?.length <= 10) {
      setShowOnlyFive(OnlyFive)
    }
  }, [page, location?.state?.page])

  const sort =
    location?.state && location?.state?.sort ? location.state.sort : "all"

  const handleNextClick = () => {
    setPage(page + 1)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${page + 1}&s=${sort}`,
      state: {
        [urlTitle]: page + 1,
        sort: sort,
      },
    })
  }
  const handlePrevClick = () => {
    setPage(page - 1)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${page - 1}&s=${sort}`,
      state: {
        [urlTitle]: page - 1,
        sort: sort,
      },
    })
  }
  const handleNumClick = item => {
    setPage(item)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${item}&s=${sort}`,
      state: {
        [urlTitle]: item,
        sort: sort,
      },
    })
  }

  React.useEffect(() => {
    console.log("page", location?.state)

    if (location?.state && location?.state?.page) {
      setPage(location?.state?.page)
    }
  }, [location?.state?.page])

  const handleSubmit = e => {
    e.preventDefault()
    setPage(pageNumber)
    history.push({
      pathname: location.pathname,
      search: `${urlTitle}=${pageNumber}&s=${sort}`,
      state: {
        page: pageNumber,
        sort: sort,
      },
    })
  }

  return (
    <Row
      className="align-items-md-center  mt-30 "
      style={{ marginTop: "2rem" }}
    >
      <Col
        style={{
          background: "#EFF5F5",
          padding: 12,
          borderRadius: 8,
        }}
        className="align-items-center inner-custom-pagination  d-flex pagination pagination-rounded justify-content-center mb-2 inner-custom-pagination"
      >
        <form
          onSubmit={handleSubmit}
          className="d-flex align-items-center"
          style={{ flex: 1 }}
        >
          <p className="p-0 m-0 pe-1 font-size-12">Page </p>
          <Input
            name="page"
            type="number"
            min={1}
            max={totalPages?.length}
            style={{ width: 45, height: 30, border: "1px solid green" }}
            value={pageNumber}
            onChange={e => setPageNumber(e.target.value)}
            // defaultValue={pageNumber}
          />
          <p className="p-0 m-0 px-1 font-size-12"> of {totalPages?.length}</p>
          <button
            type="submit"
            style={{ width: 60, height: 30 }}
            className={`ms-2 btn tickbtn d-flex align-items-center justify-content-center  btn-warning`}
          >
            Go
            <i className="fas fa-angle-right ms-1"></i>
          </button>
        </form>
        <div className="text-md-right overflowScroll">
          {page <= 1 ? (
            <></>
          ) : (
            <div
              className="btn-group me-0 "
              role="group"
              aria-label="First group"
            >
              <span
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                className="btn btn-outline-light text-warning "
                onClick={handlePrevClick}
              >
                <i className="fas fa-angle-left"></i>
              </span>
            </div>
          )}
          {totalPages?.length >= 2 && (
            <div
              className="btn-group me-2 "
              role="group"
              aria-label="Second group"
            >
              {map(showOnlyFive, (item, index) => (
                <span
                  key={index}
                  className="btn btn-outline-warning"
                  onClick={() => handleNumClick(item)}
                  style={{
                    borderRadius: "50%",
                    marginLeft: "5px",
                    marginRight: "5px",
                    border: "none",
                    backgroundColor: apiPage() == item && "#EC942C",
                    color: apiPage() == item && "#fff",
                  }}
                >
                  {item}
                </span>
              ))}
            </div>
          )}
          {page >= totalPages?.length ? (
            <></>
          ) : (
            <div className="btn-group" role="group" aria-label="Third group">
              <span
                className="btn btn-outline-light text-warning"
                style={{
                  borderRadius: "50%",
                  border: "none",
                }}
                onClick={handleNextClick}
              >
                <i className="fas fa-angle-right"></i>
              </span>
            </div>
          )}
        </div>
      </Col>
    </Row>
  )
}

MyPagination.propTypes = {
  totalPages: PropTypes.array,
  page: PropTypes.any,
  setPage: PropTypes.func,
  title: PropTypes.string,
}

export default MyPagination
