import React from "react"
import { Col, Container, Row } from "reactstrap"

import Breadcrumbs from "../../components/Common/Breadcrumb"
import MetaTag from "components/Common/Meta-tag"
import AllVarientTables from "pages/Varient/DatatableTables"
import AllModels from "pages/Model/DatatableTables"
import AllBrands from "pages/Brand/DatatableTables"

function Category() {
  return (
    <>
      <MetaTag title={"All Category"} />

      <div className="page-content">
        <Breadcrumbs title="Dashboard" breadcrumbItem="All Category" />
        <Container fluid>
          <div className="container-fluid row">
            <Col md="12" className="mb-4">
              <h5 className="p-1 mb-0 pb-0">Brands :</h5>
              <AllBrands />
            </Col>
            <Col md="6">
              <h5 className="p-1">Models :</h5>
              <AllModels />
            </Col>
            <Col md="6">
              <h5 className="p-1">Variants :</h5>
              <AllVarientTables />
            </Col>
          </div>
        </Container>
      </div>
    </>
  )
}

export default Category
