import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next"

import { debounce, map, range } from "lodash"
import moment from "moment"
import Select from "react-select"
import ToolkitProvider from "react-bootstrap-table2-toolkit"
import MyPagination from "../../components/Common/MyPagination"

//actions

import "../../assets/scss/datatables.scss"
import { AvField, AvForm } from "availity-reactstrap-validation"
import DeleteModal from "components/Common/DeleteModal"
import { deleteVariant, getModels, getVariants } from "store/actions"
import FormModal from "./Crud/Update/Form"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const AllVarientTables = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [modelValue, setModelValue] = useState("Select Model...")
  const [modelValueId, setModelValueId] = useState("")
  const [modelSearch, setModelSearch] = useState("")

  const { allVariants, total, loading, model } = useSelector(state => ({
    allVariants: state.Varient?.variants?.variants,
    total: state.Varient?.variants?.total,
    loading: state.Varient.loading,
    model: state.Model.models?.models,
  }))

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [pageVariant, setPageVariant] = useState(1)

  const [createModal, setCreateModal] = useState({ status: false, data: "" })
  const [userSearchText, setUserSearchText] = useState("")

  //delete
  const [isOpen, setIsOpen] = useState({ stauts: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, stauts: false })
    dispatch(deleteVariant(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ stauts: true, id: id })
  }
  const updatedLocation = {
    ...location,
    state: {
      ...location.state,
      page: 1,
    },
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const variantPages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(
      getVariants(pageVariant, totalItemLimit, userSearchText, modelValueId)
    )
  }, [dispatch, pageVariant, totalItemLimit, userSearchText, modelValueId])

  const onCloseClickCreate = () => {
    setCreateModal({ status: false, data: "" })
  }

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
    },

    {
      dataField: "action",
      text: "Action",
    },
  ]

  const modelData = map(allVariants, (item, index) => ({
    ...item,
    createdAt: <span>{moment(item?.createdAt).format("MMM Do YY")}</span>,
    description: (
      <p>
        {item?.description?.length >= 80
          ? item?.description?.slice(0, 80 - 1) + "..."
          : item?.description}
      </p>
    ),
    action: (
      <div>
        <Button
          id={`view${index}`}
          type="button"
          color="white"
          className="ms-1 btn"
          onClick={() => setCreateModal({ status: true, data: item })}
        >
          <i
            className="bx bx-show-alt me-2"
            style={{ color: "green", fontSize: "22px" }}
          />
        </Button>

        <Button
          id={`delete${index}`}
          type="button"
          color="white"
          className="ms-1 btn "
          onClick={() => {
            handleDelete(item?._id)
          }}
        >
          <i
            className="bx bx-trash me-2"
            style={{ color: "red", fontSize: "20px" }}
          />
        </Button>
      </div>
    ),
  }))

  // eslint-disable-next-line no-unused-vars
  const handleTableChange = (type, { page, searchText }) => {
    setUserList()
  }

  const debounceUserSearch = debounce(value => {
    setUserSearchText(value)
    history.push(updatedLocation)
  }, 600)

  useEffect(() => {
    const currentLimit = localStorage.getItem("userLimit")
    if (currentLimit) {
      setTotalItemLimit(currentLimit)
    }
  }, [])

  const handlePageLimit = e => {
    if (e.target.value >= 1) {
      setTotalItemLimit(parseInt(e.target.value))
      localStorage.setItem("userLimit", e.target.value)
    }
  }

  useEffect(() => {
    dispatch(getModels("", "", modelSearch))
  }, [dispatch, modelSearch])

  const handleEnters = textEntered => {
    setModelSearch(textEntered)
  }

  function handlerFinalValueModel(event) {
    setModelValue(event.label)
    setModelValueId(event.value)
    history.push(updatedLocation)
  }

  const modelOptions = [
    {
      label: "All",
      value: "",
    },
    {
      options: (model?.length >= 1 ? model : [])?.map((results, index) => ({
        key: index,
        label: results.name,
        value: results._id,
      })),
    },
  ]

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.stauts}
        onCloseClick={() => setIsOpen({ ...isOpen, stauts: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <FormModal
        show={createModal?.status}
        onCloseClick={onCloseClickCreate}
        updateData={createModal?.data}
      />
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              <div>
                <ToolkitProvider
                  keyField="_id"
                  columns={columns}
                  data={modelData}
                  search
                >
                  {toolkitProps => (
                    <React.Fragment>
                      <Row className="py-2">
                        <Col md="5" className="search-box mb-2 d-inline-block">
                          <div className="position-relative">
                            <AvForm>
                              <AvField
                                name="searchText"
                                placeholder="Search"
                                type="text"
                                onChange={e =>
                                  debounceUserSearch(e.target.value)
                                }
                              />
                            </AvForm>
                            <i className="bx bx-search-alt search-icon" />
                          </div>
                        </Col>
                        {/* <Col md="3">
                          <AvForm className="form-horizontal mx-2 mb-3 d-flex align-items-center">
                            <div className="mx-1">
                              <span className="">
                                Show
                                <i className="fas fa-sort-numeric-down-alt mx-1"></i>
                              </span>
                            </div>
                            <div className="" style={{ width: "50px" }}>
                              <AvField
                                name="pageLimit"
                                className="form-control"
                                value={totalItemLimit}
                                onChange={e => handlePageLimit(e)}
                                type="text"
                                required
                              />
                            </div>
                          </AvForm>
                        </Col> */}
                        <Col md="7">
                          <Select
                            onInputChange={handleEnters}
                            value={modelValue}
                            placeholder={modelValue}
                            onChange={handlerFinalValueModel}
                            options={modelOptions}
                            classNamePrefix="select2-selection"
                            isLoading={loading}
                          />{" "}
                        </Col>
                        <Col md="12">
                          <div className="d-flex align-items-center justify-content-end">
                            <Button
                              className="px-3"
                              color="warning"
                              onClick={() => setCreateModal({ status: true })}
                            >
                              <i className="mdi mdi-plus me-1" />
                              New Variant
                            </Button>
                          </div>
                        </Col>
                      </Row>
                      {/* <div className="d-flex align-items-center p-2">
                        <div className="">
                          
                    
                        </div>
                        <div>
                         
                        </div>
                        <div>
                        
                        </div>
                      </div> */}
                      {loading ? (
                        <Spinner color="secondary" className="d-block m-auto" />
                      ) : (
                        <>
                          {allVariants?.length ? (
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // keyField={"id"}
                                    key={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    // defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={"table align-middle table-nowrap"}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    onTableChange={handleTableChange}
                                  />
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <p
                              className="my-4 mx-3 "
                              style={{ textTransform: "capitalize" }}
                            >
                              No Datas!
                            </p>
                          )}
                          <MyPagination
                            totalPages={variantPages}
                            page={pageVariant}
                            setPage={setPageVariant}
                            title="vrnt"
                          />
                        </>
                      )}
                    </React.Fragment>
                  )}
                </ToolkitProvider>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default AllVarientTables
