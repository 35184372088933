import React, { useEffect, useState } from "react"
import { Row, Col, Card, CardBody, Button, Spinner } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import { isEmpty, range } from "lodash"

import MyPagination from "../../components/Common/MyPagination"

//actions
import "../../assets/scss/datatables.scss"
import DeleteModal from "components/Common/DeleteModal"
import { defaultImg } from "assets/images"
import { deleteBrand, getBrands } from "store/actions"
import BrandCrudModal from "./Crud/Update/Form"
import { Link } from "react-router-dom/cjs/react-router-dom.min"

const AllBrands = () => {
  const dispatch = useDispatch()
  const { AllBrands, total, loading } = useSelector(state => ({
    AllBrands: state.Brand.brands?.brands,
    total: state.Brand.brands?.total,
    loading: state.Brand.loading,
  }))

  const [brandModal, setBrandModal] = useState({ status: false, data: "" })

  const [totalItemLimit, setTotalItemLimit] = useState(10)
  const [pageBrand, setPageBrand] = useState(1)
  const [sort, setSort] = useState("user")

  //delete
  const [isOpen, setIsOpen] = useState({ status: false, id: "" })
  const handleDeleteEvent = () => {
    setIsOpen({ ...isOpen, status: false })
    dispatch(deleteBrand(isOpen?.id, history))
  }
  const handleDelete = id => {
    setIsOpen({ status: true, id: id })
  }

  //pagination
  const totalPages = Math.ceil(total / totalItemLimit)
  const brandPages = range(1, totalPages + 1)

  useEffect(() => {
    dispatch(getBrands(pageBrand, sort, totalItemLimit, ""))
  }, [dispatch, pageBrand, sort, totalItemLimit])

  const onCloseClickBrand = () => {
    setBrandModal({ status: false, data: "" })
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={isOpen?.status}
        onCloseClick={() => setIsOpen({ ...isOpen, status: false })}
        onDeleteClick={handleDeleteEvent}
      />
      <BrandCrudModal
        show={brandModal?.status}
        onCloseClick={() =>
          onCloseClickBrand({ ...brandModal, status: false, data: "" })
        }
        updateData={brandModal?.data}
      />
      <div className="d-flex justify-content-end">
        <Button
          className="px-4"
          color="warning"
          onClick={() => setBrandModal({ status: true, data: "" })}
        >
          <i className="mdi mdi-plus me-1" />
          New Brand
        </Button>
      </div>
      <Row style={{ minHeight: "300px" }}>
        {loading ? (
          <Spinner color="secondary" className="d-block m-auto" />
        ) : (
          <>
            {!isEmpty(AllBrands) &&
              AllBrands?.map((item, key) => (
                <Col xl="2" sm="6" key={"_col_" + key}>
                  <Card
                    style={{ minWidth: 100, maxWidth: 200 }}
                    className="hover-zoom-1"
                  >
                    <div
                      className="d-flex align-items-center justify-content-end p-2"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleDelete(item?._id)
                      }}
                    >
                      <i className="bx bxs-trash-alt text-danger" />
                    </div>
                    <CardBody>
                      <Link to="#">
                        <div className="product-img position-relative ">
                          <img
                            onClick={() =>
                              setBrandModal({ status: true, data: item })
                            }
                            style={{ width: "70px", height: "70px" }}
                            src={
                              item?.logo && item?.logo[0]
                                ? item?.logo[0]?.url
                                : defaultImg
                            }
                            alt=""
                            className="img-fluid mx-auto d-block"
                          />
                        </div>
                      </Link>

                      <div className="mt-4 text-center">
                        <h5 className="mb-3 text-truncate">{item.name} </h5>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))}
            <MyPagination
              totalPages={brandPages}
              page={pageBrand}
              setPage={setPageBrand}
              title="brand"
            />
          </>
        )}
      </Row>
    </React.Fragment>
  )
}

export default AllBrands
